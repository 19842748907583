import React from 'react'

const GoalsStepTwo = () => {
  return (
    <div>
      <h1>Goals Step Two</h1>
    </div>
  )
}

export default GoalsStepTwo
