// import { getAuth } from 'firebase/auth'
import React from 'react'
import { Link } from 'react-router-dom'
// import StepsContext from '../context/stepsContext'

const Goals = () => {
  // const auth = getAuth()

  const onNextStep = () => {}

  // const { steps } = useContext(StepsContext)

  return (
    <div className='mb-40 p-4'>
      <header className='flex justify-between items-center'>
        <p className='text-4xl font-extrabold'>Goals</p>
        <button
          type='button'
          className='btn btn-secondary btn-xs py-1 px-3 rounded-2xl'
          onClick={onNextStep}
        >
          Next Step
        </button>
      </header>

      <main>
        <div>
          <ul className='steps steps-vertical md:steps-horizontal'>
            <li className='step step-primary'>
              <Link
                // to={`/${auth.currentUser.uid}/:groupId/step1`}
                to={'/userId/groupId/step1'}
                className='pointer'
              >
                100 Goals
              </Link>
            </li>

            <li className='step'>
              <Link to='/:userId/:groupId/step2' className='pointer'>
                Assign Timeline
              </Link>
            </li>
            <li className='step'>
              <Link to='/:userId/:groupId/step3' className='pointer'>
                Pick 3
              </Link>
            </li>
            <li className='step'>
              <Link to='/:userId/:groupId/step4' className='pointer'>
                Achieve
              </Link>
            </li>
          </ul>
        </div>
        <div className='p-6 space-y-2 artboard phone'>
          <progress
            className='progress progress-primary'
            value='10'
            max='100'
          ></progress>
        </div>
      </main>
    </div>
  )
}

export default Goals
