import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import * as Yup from 'yup'

import { Link, useNavigate } from 'react-router-dom'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'
import { toast } from 'react-toastify'
import OAuth from '../components/OAuth'
import AuthContext from '../context/AuthContext'

const SignIn = () => {
  const [passwordShown, setPasswordShown] = useState(false)

  const authCtx = useContext(AuthContext)

  const navigate = useNavigate()

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
  })

  const formOptions = { resolver: yupResolver(validationSchema) }

  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm(formOptions)

  const onSubmit = async (data, e) => {
    e.preventDefault()

    try {
      const auth = getAuth()

      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )

      if (userCredential.user) {
        const expirationTime = new Date(
          new Date().getTime() +
            +userCredential.user.stsTokenManager.expirationTime
        )

        authCtx.login(
          userCredential.user.accessToken,
          userCredential.user.uid,
          expirationTime.toISOString()
        )

        navigate('/dashboard')
      }
    } catch (error) {
      toast.error('Bad User Credentials')
    }
  }

  return (
    <div className='m-2'>
      <header>
        <p className='text-xl mb-3 px-5'>Welcome Back!</p>
      </header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='p-3 card bg-base-200'>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>Email</span>
            </label>
            <input
              {...register('email', { required: 'Email is required.' })}
              type='text'
              placeholder='email'
              className='input'
              autoComplete='username'
            />
            <p className='text-red-400'>{errors.email?.message}</p>
          </div>

          <div className='form-control passwordInputDiv'>
            <label className='label'>
              <span className='label-text'>Password</span>
            </label>
            <input
              {...register('password')}
              type={passwordShown ? 'text' : 'password'}
              placeholder='password'
              className='input'
              autoComplete='current-password'
            />
            {/* <i onClick={() => setPasswordShown(prevState => !prevState)}>
              <AiFillEye />{' '}
            </i> */}
            <img
              src={visibilityIcon}
              alt='show password'
              className='showPassword'
              onClick={() => setPasswordShown(prevState => !prevState)}
            />
            <p className='text-red-400'>{errors.password?.message}</p>
          </div>
        </div>

        <div className='w-full text-right mt-6 btn-link'>
          <Link to='/forgot-password'>Forgot Password</Link>
        </div>

        <button className='btn btn-block btn-primary mt-5' type='submit'>
          Sign In
        </button>
      </form>

      <OAuth />

      <div className='btn-link w-full text-center mt-10'>
        <Link to='/sign-up'>Sign Up Instead</Link>
      </div>
    </div>
  )
}

export default SignIn
