import React, { createContext, useState } from 'react'

const AuthContext = createContext({
  token: '',
  isLoggedIn: false,
  userId: '',
  login: (token, id) => {},
  logout: () => {}
})

const calculateRemainingTime = experiationTime => {
  const currentTime = new Date().getTime()
  const adjExpirationTime = new Date(experiationTime).getTime()

  const remainingDuration = adjExpirationTime - currentTime

  return remainingDuration
}

export const AuthContextProvider = props => {
  const initialToken = localStorage.getItem('token')
  const initialUserId = localStorage.getItem('userId')
  const [token, setToken] = useState(initialToken)
  const [userId, setUserId] = useState(initialUserId)

  const userIsLoggedIn = !!token

  const logoutHandler = () => {
    setToken(null)
    setUserId(null)
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
  }

  const loginHandler = (token, id, expirationTime) => {
    setToken(token)
    setUserId(id)
    localStorage.setItem('token', token)
    localStorage.setItem('userId', id)

    const remainingTime = calculateRemainingTime(expirationTime)
    console.log(remainingTime)

    // setTimeout(logoutHandler, remainingTime)
  }

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    userId: userId,
    login: loginHandler,
    logout: logoutHandler
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
