import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const PrivateRoute = () => {
  const authCtx = useContext(AuthContext)

  // const { loggedIn, checkingStatus } = useAuthStatus()
  const loggedIn = authCtx.isLoggedIn

  // if (checkingStatus) {
  //   return <Spinner />
  // }

  return loggedIn ? <Outlet /> : <Navigate to='/sign-in' />
}

export default PrivateRoute
