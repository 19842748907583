import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GoalsContext from '../context/GoalsContext'
import CategoryItem from './CategoryItem'

const GoalsList = ({ userRef }) => {
  const { goals } = useContext(GoalsContext)
  const [categories, setCategories] = useState([])

  // useEffect(() => {}, [goals, categories])

  useEffect(() => {
    const newCategories = Array.from(
      new Set(goals.map(goal => goal.data.category))
    )

    setCategories(newCategories)
  }, [goals])

  const fillerCategory = 'physical'

  if (!goals || goals.length === 0) {
    return <p>No goals to display</p>
  }

  return (
    <div>
      {goals.length > 99 && (
        <Link
          className='btn btn-primary w-full my-3'
          to='/:groupId/:userId/step2'
        >
          Proceed to Step 2
        </Link>
      )}

      {categories ? (
        categories.map((category, index) => {
          return (
            <ul key={index}>
              <li>
                <CategoryItem category={category} userRef={userRef} />
              </li>
            </ul>
          )
        })
      ) : (
        <h2>
          <CategoryItem category={fillerCategory} />
        </h2>
      )}
    </div>
  )
}

export default GoalsList
