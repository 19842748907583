import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'
import { setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'
import { toast } from 'react-toastify'
import OAuth from '../components/OAuth'
import AuthContext from '../context/AuthContext'

const SignUp = () => {
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

  const authCtx = useContext(AuthContext)

  const navigate = useNavigate()

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match')
  })

  const formOptions = { resolver: yupResolver(validationSchema) }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(formOptions)

  const onSubmit = async (data, e) => {
    e.preventDefault()

    try {
      const auth = getAuth()

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )

      const user = userCredential.user

      updateProfile(auth.currentUser, {
        displayName: data.name
      })

      const formDataCopy = { ...data }
      delete formDataCopy.password
      delete formDataCopy.confirmPassword
      formDataCopy.timestamp = serverTimestamp()

      await setDoc(doc(db, 'users', user.uid), formDataCopy)

      const expirationTime = new Date(
        new Date().getTime() + +user.stsTokenManager.expirationTime
      )

      authCtx.login(user.accessToken, user.uid, expirationTime.toISOString())

      navigate('/dashboard')
    } catch (error) {
      toast.error('Something went wrong with registration!')
    }
  }

  return (
    <div className='m-2'>
      <header>
        <p className='text-xl mb-3 px-5'>Welcome!</p>
      </header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='p-3 card bg-base-200'>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>Full Name</span>
            </label>
            <input
              {...register('name', {
                required: 'Full Name is required.'
              })}
              type='text'
              placeholder='full name'
              className='input'
            />
            <p className='text-red-400'>{errors.name?.message}</p>
          </div>

          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>Email</span>
            </label>
            <input
              {...register('email', { required: 'Email is required.' })}
              type='text'
              placeholder='email'
              className='input'
              autoComplete='username'
            />
            <p className='text-red-400'>{errors.email?.message}</p>
          </div>

          <div className='form-control passwordInputDiv'>
            <label className='label'>
              <span className='label-text'>Password</span>
            </label>
            <input
              {...register('password', {
                required: 'Password is required.',
                minLength: 6
              })}
              type={passwordShown ? 'text' : 'password'}
              placeholder='password'
              className='input'
              autoComplete='new-password'
            />
            <img
              src={visibilityIcon}
              alt='show password'
              className='showPassword'
              onClick={() => setPasswordShown(prevState => !prevState)}
            />
            <p className='text-red-400'>{errors.password?.message}</p>
          </div>

          <div className='form-control passwordInputDiv'>
            <label className='label'>
              <span className='label-text'>Confirm Password</span>
            </label>
            <input
              {...register('confirmPassword', {
                required: 'Password is required.',
                minLength: 6
              })}
              type={confirmPasswordShown ? 'text' : 'password'}
              placeholder='confirm password'
              className='input'
              autoComplete='new-password'
            />
            <img
              src={visibilityIcon}
              alt='show password'
              className='showPassword'
              onClick={() => setConfirmPasswordShown(prevState => !prevState)}
            />
            <p className='text-red-400'>{errors.confirmPassword?.message}</p>
          </div>
        </div>

        <button className='btn btn-block btn-primary mt-5' type='submit'>
          Sign Up
        </button>
      </form>

      <OAuth />

      <div className='btn-link w-full text-center mt-10'>
        <Link to='/sign-in'>Sign In Instead</Link>
      </div>
    </div>
  )
}

export default SignUp
