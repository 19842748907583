import React, { useContext } from 'react'
import { FaTimes, FaEdit } from 'react-icons/fa'
import GoalsContext from '../context/GoalsContext'

const GoalItem = ({ goal, userRef }) => {
  const { deleteGoal, editGoal } = useContext(GoalsContext)
  return (
    <div className='card card-xs bg-white text-primary p-1 flex-row justify-between mb-1'>
      {/* <div className='text-sm'>{goal.data.category.toUpperCase()}</div> */}
      <div className='p-1'>{goal.data.description}</div>
      <div className='flex'>
        <button className=' p-1' onClick={() => editGoal(goal)}>
          <FaEdit />
        </button>
        <button onClick={() => deleteGoal(goal.id)}>
          <FaTimes color='red' />
        </button>
      </div>

      {/* <div className='badge'>{category}</div> */}
    </div>
  )
}

export default GoalItem
