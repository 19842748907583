import { createContext, useContext, useEffect, useState } from 'react'
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  deleteDoc,
  doc
} from 'firebase/firestore'

import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import AuthContext from './AuthContext'

const GoalsContext = createContext()

export const GoalsProvider = ({ children }) => {
  const [goals, setGoals] = useState([])
  const [categories, setCategories] = useState([])
  // const [userID, setUserID] = useState('')

  const authCtx = useContext(AuthContext)

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      // setUserID(authCtx.userId)
      // fetchGoals()
    }
    // return () => {
    //   setGoals({})
    //   setCategories({})
    // }
  }, [authCtx])

  const [goalEdit, setGoalEdit] = useState({
    goal: {},
    edit: false
  })

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  const [loading, setLoading] = useState(true)

  // add Goal
  const addGoal = async newGoal => {
    // setLoading(true)

    const docRef = await addDoc(collection(db, 'goals'), newGoal)
    setGoals([{ id: docRef.id, data: newGoal }, ...goals])

    // fetchGoals()
    // setLoading(false)
  }

  // delete goal
  const deleteGoal = async id => {
    if (window.confirm('Are you sure you want to delete?')) {
      setGoals(goals.filter(goal => goal.id !== id))

      await deleteDoc(doc(db, 'goals', id))
    }
  }

  // update goal
  const updateGoal = (id, updGoal) => {
    setGoals(
      goals.map(goal => (goal.id === id ? { ...goal, ...updGoal } : goal))
    )
    setGoalEdit({
      edit: false
    })
  }

  // Set Goal to be updated
  const editGoal = goal => {
    setGoalEdit({
      goal,
      edit: true
    })
    setEditModalIsOpen(true)
  }

  const closeEdit = () => {
    setGoalEdit({ edit: false })
    setEditModalIsOpen(false)
  }

  const fetchGoals = async () => {
    try {
      // const auth = getAuth()
      // const userID = await auth.currentUser.uid
      const userID = authCtx.userId

      const goalsRef = collection(db, 'goals')

      const q = query(
        goalsRef,
        // orderBy('category', 'asc'),
        where('userRef', '==', userID)
        // where('groupRef', '==', params.groupId),
      )

      // execute query
      const querySnap = await getDocs(q)

      const goals = []

      querySnap.forEach(doc => {
        return goals.push({
          id: doc.id,
          data: doc.data()
        })
      })

      const newCategories = await Array.from(
        new Set(goals.map(goal => goal.data.category))
      )

      setGoals(goals)
      setCategories(newCategories)
      setLoading(false)
    } catch (error) {
      toast.error('Could not fetch goals.')
    }
  }

  // const fetchCategories = async () => {
  //   try {
  //     const newCategories = await Array.from(
  //       new Set(goals.map(goal => goal.data.category))
  //     )

  //     setCategories(newCategories)
  //   } catch (error) {}
  // }

  return (
    <GoalsContext.Provider
      value={{
        goals,
        fetchGoals,
        deleteGoal,
        addGoal,
        editGoal,
        goalEdit,
        updateGoal,
        closeEdit,
        editModalIsOpen,
        setEditModalIsOpen,
        categories,
        loading
      }}
    >
      {children}
    </GoalsContext.Provider>
  )
}

export default GoalsContext
