import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className='mb-40 p-4'>
      <header className='flex justify-between items-center mb-10'>
        <p className='text-4xl font-extrabold'>Welcom to EaGoals</p>
      </header>
      {/* <Link to='/sign-up' className='btn btn-accent w-full mt-10'>
        Sign Up
      </Link>
      <Link to='/sign-in' className='btn btn-primary w-full mt-2'>
        Sign In
      </Link> */}

      <div className='card shadow-xl image-full mb-5'>
        <figure>
          <img src='https://picsum.photos/id/1005/400/250' alt='' />
        </figure>
        <div className='justify-end card-body'>
          <h2 className='card-title'>Achieve</h2>
          <p>
            EaGoals is a revolutionary web app that enables you to accomplish
            your wildest dreams
          </p>
          <div className='card-actions'>
            <Link to='/sign-up' className='btn btn-accent w-full'>
              Get Started
            </Link>
          </div>
        </div>
      </div>

      {/* <div className='flex items-center w-full px-4 py-10 bg-cover card bg-base-200'> */}
      <div className='card glass lg:card-side text-neutral-content'>
        <figure className='p-6'>
          <img
            src='https://learnloft.com/wp-content/uploads/2016/12/shutterstock_544407421.jpg'
            alt=''
          />
        </figure>
        <div className='max-w-md card-body'>
          <h2 className='card-title'>Groups</h2>
          <p>
            Create or Join a group of your mastermind, friends, co-workers,
            employees, family, or anyone else you want to accomplish major life
            goals with.
          </p>
          <div className='card-actions'>
            <Link to='/sign-up' className='btn glass rounded-full w-full'>
              Get Started Now!
            </Link>
          </div>
        </div>
      </div>
      {/* </div> */}

      <main>
        <div className='card p-2 bg-base'>
          <p></p>
        </div>
        <div>
          <p></p>
        </div>
      </main>
    </div>
  )
}

export default Home
