import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const onChange = e => {
    setEmail(e.target.value)
  }

  const onSubmit = async e => {
    e.preventDefault()

    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      toast.success('Email was sent')
    } catch (error) {
      toast.error('Could not send reset email')
    }
  }
  return (
    <div className='m-2'>
      <header>
        <p className='text-xl mb-3 px-5'>Forgot Password</p>
      </header>

      <main>
        <form onSubmit={onSubmit}>
          <div className='p-3 card bg-base-200'>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Email</span>
              </label>
              <input
                type='email'
                className='input'
                placeholder='Email'
                id='email'
                value={email}
                onChange={onChange}
              />
            </div>
          </div>
          <div className='w-full text-right '>
            <Link to='/sign-in' className='btn btn-ghost'>
              Sign In
            </Link>
          </div>

          <button className='btn btn-accent w-full my-5'>
            Send Reset Link
          </button>
        </form>
      </main>
    </div>
  )
}

export default ForgotPassword
