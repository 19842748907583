import { createContext, useState } from 'react'
const StepsContext = createContext()

export const StepsProvider = ({ children }) => {
  const [steps, setSteps] = useState(
    {
      id: 1,
      step: 1,
      status: 'complete'
    },
    {
      id: 2,
      step: 2,
      status: 'incomplete'
    }
  )

  const submitStep = id => {
    if (
      window.confirm(
        'Are you sure you want to submit this step and move on to the next?'
      )
    ) {
      setSteps([])
    }
  }

  return (
    <StepsContext.Provider value={{ steps, submitStep }}>
      {children}
    </StepsContext.Provider>
  )
}

export default StepsContext
