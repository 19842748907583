import { getAuth, onAuthStateChanged } from 'firebase/auth'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import AddGoalForm from '../components/AddGoalForm'

import GoalsList from '../components/GoalsList'

import GoalsContext from '../context/GoalsContext'

const customStyles = {
  content: {
    // width: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
    // position: 'relative'
  }
}

Modal.setAppElement('#root')

const GoalsStepOne = () => {
  // const [editModalIsOpen, setEditModalIsModal] = useState(false)
  const {
    goalEdit,
    editModalIsOpen,
    setEditModalIsOpen,
    fetchGoals,
    goals
  } = useContext(GoalsContext)

  const [userRef, setUserRef] = useState('')
  const [goalsCounter, setGoalsCounter] = useState(0)

  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted) {
      fetchGoals()
      setGoalsCounter(goals.length)
      onAuthStateChanged(auth, user => {
        if (user) {
          setUserRef({ userRef: user.uid })
        } else {
          navigate('/sign-in')
        }
      })
    }

    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, goals])

  useEffect(() => {
    if (goalEdit.edit === true) {
      // setBtnDisabled(false)
      // setText(goalEdit.goal.goal)
      // setCategorySelected(goalEdit.goal.category)
      setEditModalIsOpen(true)
    } else {
      setEditModalIsOpen(false)
    }
  }, [goalEdit, editModalIsOpen, setEditModalIsOpen])
  return (
    <>
      {/* {editModalIsOpen && <EditGoalForm />} */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        style={customStyles}
        contentLabel='Edit Goal'
      >
        <AddGoalForm userRef={userRef} />
      </Modal>
      <div className='mb-40 p-4'>
        <header className='flex justify-between items-center'>
          <p className='text-4xl font-extrabold'>
            <small className='text-xs text-primary'>Step 1</small> 100 Goals
          </p>

          <div className='m-2'>
            <button className='btn btn-primary btn-xs'>
              Goals
              <div className='badge ml-2 badge'>{goalsCounter}</div>
            </button>
          </div>
        </header>

        <main>
          <div
            tabIndex='0'
            className='my-4 collapse-compact collapse w-full border rounded-box border-base-300 collapse-plus'
          >
            <div className='collapse-title text-xl font-medium'>
              Add 100 Goals
            </div>
            <div className='collapse-content'>
              <p>
                Coming up with 100 goals seems like a daunting task. But I
                promise, once you get going, the goals start flowing. In this
                step you will come up with 100 goals that you would like to
                acheive in your lifetime. No goal is too big or too small. To
                complete this step, type the name of the goal and select a
                category for it, then click add.
              </p>
            </div>
          </div>

          <div className='mb-2'>
            <AddGoalForm userRef={userRef} />
          </div>

          {/* <div>
            <GoalsListTableView userRef={userRef} />
          </div> */}

          <div>
            <GoalsList userRef={userRef} />
          </div>
        </main>
      </div>
    </>
  )
}

export default GoalsStepOne
