// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA1Ig1x7AyjR-9ikDey4h0xvioOcjgK8fk',
  authDomain: 'eagles-group-app.firebaseapp.com',
  projectId: 'eagles-group-app',
  storageBucket: 'eagles-group-app.appspot.com',
  messagingSenderId: '321038564295',
  appId: '1:321038564295:web:768e7be144d0d0f12af7e2'
}

// Initialize Firebase
initializeApp(firebaseConfig)
export const db = getFirestore()
