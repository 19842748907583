import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as ExploreIcon } from '../assets/svg/exploreIcon.svg'
import { ReactComponent as PersonOutlineIcon } from '../assets/svg/personOutlineIcon.svg'
import { ReactComponent as GoalsIcon } from '../assets/svg/goalsIcon.svg'

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const pathMatchRoute = route => {
    if (route === location.pathname) {
      return true
    }
  }
  return (
    <footer className='footerBar bg-base-200'>
      <div className='navbarNav'>
        <ul className='navbarListItems'>
          <li className='navbarListItem' onClick={() => navigate('/dashboard')}>
            <ExploreIcon
              fill={pathMatchRoute('/dashboard') ? '#793ef9' : '#ffffff'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/dashboard') ? 'text-primary' : 'text-white'
              }
            >
              Dashboard
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/goals')}>
            <GoalsIcon
              fill={pathMatchRoute('/goals') ? '#793ef9' : '#ffffff'}
            />
            <p
              className={
                pathMatchRoute('/goals') ? 'text-primary' : 'text-white'
              }
            >
              Goals
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/profile')}>
            <PersonOutlineIcon
              fill={pathMatchRoute('/profile') ? '#793ef9' : '#ffffff'}
            />
            <p
              className={
                pathMatchRoute('/profile') ? 'text-primary' : 'text-white'
              }
            >
              Profile
            </p>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Navbar
