import { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/Navbar'
import PrivateRoute from './components/PrivateRoute'
import AuthContext from './context/AuthContext'
import { GoalsProvider } from './context/GoalsContext'
import { StepsProvider } from './context/stepsContext'
import Dashboard from './pages/Dashboard'
import ForgotPassword from './pages/ForgotPassword'
import Goals from './pages/Goals'
import GoalsStepOne from './pages/GoalsStepOne'
import GoalsStepTwo from './pages/GoalsStepTwo'
import Home from './pages/Home'
import Profile from './pages/Profile'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'

function App() {
  const authCtx = useContext(AuthContext)

  const isLoggedIn = authCtx.isLoggedIn

  return (
    <>
      <GoalsProvider>
        <StepsProvider>
          <Router>
            <div>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/dashboard' element={<PrivateRoute />}>
                  <Route path='/dashboard' element={<Dashboard />} />
                </Route>

                <Route path='/goals' element={<PrivateRoute />}>
                  <Route path='/goals' element={<Goals />} />
                </Route>

                <Route
                  path='/:userId/:groupId/step1'
                  element={<PrivateRoute />}
                >
                  <Route
                    path='/:userId/:groupId/step1'
                    element={<GoalsStepOne />}
                  />
                </Route>

                <Route
                  path='/:userId/:groupId/step2'
                  element={<PrivateRoute />}
                >
                  <Route
                    path='/:userId/:groupId/step2'
                    element={<GoalsStepTwo />}
                  />
                </Route>

                <Route path='/profile' element={<PrivateRoute />}>
                  <Route path='/profile' element={<Profile />} />
                </Route>

                <Route path='/sign-in' element={<SignIn />} />
                <Route path='/sign-up' element={<SignUp />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/' element={<Dashboard />} />
              </Routes>
              {/* <Navbar /> */}
              {isLoggedIn && <Navbar />}
            </div>
          </Router>
          <ToastContainer />
        </StepsProvider>
      </GoalsProvider>
    </>
  )
}

export default App
