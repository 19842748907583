import { serverTimestamp } from '@firebase/firestore'
import React, { useContext, useState, useEffect } from 'react'
import Select from 'react-select'
import GoalsContext from '../context/GoalsContext'

const AddGoalForm = ({ userRef }) => {
  const [text, setText] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [message, setMessage] = useState('')

  const { addGoal, goalEdit, updateGoal } = useContext(GoalsContext)

  const options = [
    { value: 'physical', label: 'Physical' },
    { value: 'emotional', label: 'Emotional' },
    { value: 'intellectual', label: 'Intellectual' },
    { value: 'spiritual', label: 'Spiritual' },
    { value: 'psychological', label: 'Psychological' },
    { value: 'material', label: 'Material' },
    { value: 'professional', label: 'Professional' },
    { value: 'financial', label: 'Financial' },
    { value: 'creative', label: 'Creative' },
    { value: 'adventure', label: 'Adventure' },
    { value: 'legacy', label: 'Legacy' },
    { value: 'character', label: 'Character' }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#fff' : '#570df8',
      backgroundColor: state.isFocused ? '#f000b8' : 'white'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      border: '1px solid #570df8'
      // borderBottom: '1px dotted pink'
    }),
    singleValue: provided => ({
      ...provided,
      color: '#570df8'
    }),
    placeholder: provided => ({
      ...provided,
      color: '#570df8'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#570df8'
    })
  }

  useEffect(() => {
    if (goalEdit.edit === true) {
      setBtnDisabled(false)
      setText(goalEdit.goal.goal)
      setCategorySelected(goalEdit.goal.category)
    } else {
      setBtnDisabled(true)
      setText('')
      setCategorySelected(null)
    }
  }, [goalEdit])

  const handleTextChange = e => {
    setText(e.target.value)
    setBtnDisabled(false)
  }
  const handleCategorySelected = e => {
    setCategorySelected(e.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (categorySelected === '' || categorySelected === null) {
      setMessage('You must select a category')
      return
    }
    const newGoal = {
      category: categorySelected,
      description: text,
      timestamp: serverTimestamp(),
      userRef: userRef.userRef
    }

    if (goalEdit.edit === true) {
      updateGoal(goalEdit.goal.id, newGoal)
    } else {
      addGoal(newGoal)
    }

    setText('')
    setBtnDisabled(true)
    setCategorySelected(categorySelected)
  }

  const goalActionWord = goalEdit.edit ? 'Update' : 'Add'

  return (
    <div className='card bg-white text-primary pl-3'>
      <form className='form-control' onSubmit={handleSubmit}>
        <h2>{goalActionWord} Goal</h2>
        {/* @todo - category select */}
        <div className='w-full justify-between'>
          <div className=''>
            <Select
              className='m-2'
              options={options}
              option={'color: black'}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              onChange={handleCategorySelected}
              value={options.filter(function (option) {
                return option.value === categorySelected
              })}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
          <div className='relative m-2'>
            <input
              type='text'
              className='input bg-white input-primary w-full pr-16'
              placeholder='Goal #1'
              onChange={handleTextChange}
              value={text}
            />

            <button
              type='submit'
              className='absolute top-0 right-0 rounded-l-none btn btn-primary '
              disabled={btnDisabled}
            >
              {goalActionWord}
            </button>
          </div>
          <div>
            <h2>{message}</h2>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddGoalForm
