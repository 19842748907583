import React from 'react'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  // const onNextStep = () => {}

  return (
    <div className='mb-40 p-4'>
      <header className='flex justify-between items-center'>
        <p className='text-4xl font-extrabold'>Dashboard</p>
      </header>
      <main>
        <Link
          type='button'
          className='btn btn-secondary py-1 px-3 rounded-2xl my-10 w-full'
          to='/goals'
        >
          Go to Goals
        </Link>
        <div>
          <h2>My Groups</h2>
          <ul>
            <li>
              <Link to='/dashboard'>Group 1</Link>
            </li>
            <li>
              <Link to='/dashboard'>Group 2</Link>
            </li>
          </ul>
        </div>
        <Link
          type='button'
          className='btn btn-primary py-1 px-3 rounded-2xl my-10 w-full'
          to='/goals'
        >
          Create New Group
        </Link>
      </main>
    </div>
  )
}

export default Dashboard
