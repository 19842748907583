import React, { useContext, useState } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import { updateDoc, doc } from 'firebase/firestore'

import { useNavigate } from 'react-router-dom'
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import AuthContext from '../context/AuthContext'

const Profile = () => {
  const auth = getAuth()
  const [changeDetails, setChangeDetails] = useState(false)
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    // lastName: auth.currentUser.lastName,
    email: auth.currentUser.email
  })

  const authCtx = useContext(AuthContext)

  const logoutHandler = () => {
    authCtx.logout()
  }

  const { name, email } = formData

  const navigate = useNavigate()

  const onLogout = () => {
    auth.signOut()
    logoutHandler()
    navigate('/sign-in')
  }
  const onSubmit = async () => {
    try {
      if (auth.currentUser.displayName !== name) {
        // Update display name in fb
        await updateProfile(auth.currentUser, {
          displayName: name
        })

        // Update in firestore
        const userRef = doc(db, 'users', auth.currentUser.uid)
        await updateDoc(userRef, {
          name
        })
      }
    } catch (error) {
      toast.error('Could not update profile details.')
    }
  }

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  return (
    <div className='mb-40 p-4'>
      <header className='flex justify-between items-center'>
        <p className='text-4xl font-extrabold'>Profile</p>
        <button
          type='button'
          className='btn btn-secondary btn-xs py-1 px-3 rounded-2xl'
          onClick={onLogout}
        >
          Logout
        </button>
      </header>

      <main>
        <div className='flex justify-between max-w-screen-sm my-10'>
          <p className='font-semibold'>Personal Details</p>
          <p
            className='cursor-pointer font-semibold'
            onClick={() => {
              changeDetails && onSubmit()
              setChangeDetails(prevState => !prevState)
            }}
          >
            {changeDetails ? 'done' : 'change'}
          </p>
        </div>

        <div className='card'>
          <form>
            <input
              type='text'
              id='name'
              className={
                !changeDetails
                  ? 'input w-full my-2'
                  : 'input w-full bg-slate-600 my-2'
              }
              disabled={!changeDetails}
              value={name}
              onChange={onChange}
            />

            <input
              type='text'
              id='email'
              className={
                !changeDetails
                  ? 'input w-full my-2'
                  : 'input w-full bg-slate-600 my-2'
              }
              disabled={true}
              value={email}
              onChange={onChange}
            />
          </form>
        </div>
      </main>
    </div>
  )
}

export default Profile
