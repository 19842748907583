import React, { useContext, useState } from 'react'
import GoalsContext from '../context/GoalsContext'
import GoalItem from './GoalItem'
import Spinner from './Spinner'

const CategoryItem = ({ category, userRef }) => {
  const [toggleCategory, setToggleCategory] = useState('all')
  const { goals, loading } = useContext(GoalsContext)

  const handleToggleCategory = () => {
    setToggleCategory(category)
  }

  const handleToggleCategoryOff = () => {
    setToggleCategory(null)
  }

  const newGoals = goals.filter(goal => goal.data?.category === category)

  const goalsItems = newGoals.map(goal => (
    <GoalItem key={goal.id} goal={goal} userRef={userRef} />
  ))

  return (
    <>
      {loading && <Spinner />}
      <div className='card bg-neutral p-2 mb-1'>
        <div className='flex justify-between'>
          <div>{category ? category.toUpperCase() : 'No Categories'}</div>

          <div className='mb-1'>
            <button className='btn btn-primary btn-xs'>
              {category ? category.toUpperCase() : 'No Categories'} Goals
              <div className='badge ml-2 badge'>{goalsItems.length}</div>
            </button>
          </div>
          <div>
            {toggleCategory === 'all' ? (
              ''
            ) : toggleCategory !== category ? (
              <button onClick={handleToggleCategory}>+</button>
            ) : (
              ''
            )}
            {toggleCategory === category || toggleCategory === 'all' ? (
              <button onClick={handleToggleCategoryOff}>-</button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div>
          {toggleCategory === category || toggleCategory === 'all'
            ? goalsItems
            : ''}
        </div>
      </div>
    </>
  )
}

export default CategoryItem
